import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import charts from 'assets/illustrations/charts.svg';
import { defaultTitle } from '../../../../site-config';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';

export const Intro = () => (
  <Wrapper>
    <Header />
    <IntroWrapper as={Container}>
      <Details>
        <h1>Stop coding in the dark.</h1>
        <h4>{`${defaultTitle} handles your software needs so you can focus on your business.`}</h4>
        <Button as={AnchorLink} href='#data'>
          Get A Quote
        </Button>
      </Details>
      <Thumbnail>
        <img src={charts} alt='' />
      </Thumbnail>
    </IntroWrapper>
  </Wrapper>
);
