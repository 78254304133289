import React from 'react';
import { Container } from 'components/common';
import contact from 'assets/illustrations/contact.svg';
import { Wrapper, FormWrapper, Details, Thumbnail } from './styles';
import ContactForm from './ContactForm';

export const Contact = () => (
  <Wrapper as={Container} id='contact'>
    <h2>Contact Us</h2>

    <h4 style={{ margin: '1rem' }}>Have a software project?</h4>
    <h4 style={{ margin: '1rem' }}>
      Want to automatic a manual task?
    </h4>

    <FormWrapper>
      <Details>
        <ContactForm />
      </Details>
      <Thumbnail>
        <img src={contact} alt='Two engineers, engineering.' />
      </Thumbnail>
    </FormWrapper>
  </Wrapper>
);
