import React from 'react';
import { Container } from 'components/common';
import dataScience from 'assets/illustrations/data-science.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar,
  faHandHoldingUsd,
  faBolt
} from '@fortawesome/free-solid-svg-icons';
import { defaultTitle } from '../../../../site-config';
import LetUsKnow from './LetUsKnow';

import {
  Wrapper,
  BadgesWrapper,
  Details,
  Thumbnail,
  BadgeList,
  Badge,
  BadgeIcon
} from './styles';

const badges = [
  { icon: faChartBar, text: 'Measurable Results' },
  { icon: faHandHoldingUsd, text: 'Affordable pricing' },
  { icon: faBolt, text: 'Fast delivery' }
];

export const Badges = () => (
  <Wrapper id='about'>
    <BadgesWrapper as={Container}>
      <Thumbnail>
        <img src={dataScience} alt='' />
      </Thumbnail>
      <Details>
        <h2>{`Why ${defaultTitle}?`}</h2>

        <h5 style={{ margin: '1rem' }}>
          We provide for all your software and data needs!
        </h5>

        <BadgeList>
          {badges.map(badge => (
            <Badge key={badge.text}>
              <BadgeIcon>
                <FontAwesomeIcon icon={badge.icon} size='2x' />
              </BadgeIcon>
              <p>{badge.text}</p>
            </Badge>
          ))}
        </BadgeList>
        <LetUsKnow isHome />
      </Details>
    </BadgesWrapper>
  </Wrapper>
);
